import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import locations from 'routes';
import { IProperty } from 'interfaces/IProperty';
import { DASH_PLACEHOLDER, DOT } from 'constants/placeholders';
import { getCoverImageUrl } from 'components/ModalMedia/utils';
import { getPropertyAddress } from 'utils/formatters/property';
import { ColorNames } from 'constants/colorNames';
import { PropertyImageSizes } from 'constants/propertyImageSizes';
import AYLink from 'components/AYLink';
import { PropertyMapSize, ZoomLevel } from 'components/PropertyMap';
import StaticMapImage from 'components/PropertyMap/StaticMapImage';
import AlternateAddressIconSmall from 'components/AlternateAddressIconSmall';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import styles from './PropertyCardListItem.module.scss';

interface Props {
  cardBackgroundColor?: ColorNames;
  id?: string;
  isAddressClickable?: boolean;
  isDisabled?: boolean;
  noBorder?: boolean;
  property: IProperty;
  topRightButtonClassName?: string;
  wrapperClassName?: string;
  hasFeature?: boolean;
  isPropertyFeatured?: boolean;
  onTopRightButtonClick?: () => void;
  onTopLeftButtonClick?: () => void;
}

const PropertyCardListItem: React.FC<Props> = props => {
  const { t } = useTranslation();

  const {
    cardBackgroundColor,
    id,
    isAddressClickable,
    isDisabled,
    noBorder,
    property,
    topRightButtonClassName,
    wrapperClassName,
    hasFeature,
    isPropertyFeatured,
    onTopRightButtonClick,
    onTopLeftButtonClick,
  } = props;

  const propertyMarketInfo = [
    property.market?.name,
    property.submarket?.name,
    property.micromarket?.name,
  ];

  const hasTopRightButton =
    !!onTopRightButtonClick && !!topRightButtonClassName;
  const hasTopLeftButton = !!onTopLeftButtonClick && hasFeature;

  const imageSrc = getCoverImageUrl(property, PropertyImageSizes.l);
  const cardBackgroundColorClass =
    styles[`background-${cardBackgroundColor || ColorNames.ayWhiteColor}`];

  return (
    <div
      className={classNames(
        styles.container,
        wrapperClassName,
        cardBackgroundColorClass,
        {
          [styles['no-border']]: noBorder,
          [styles['disabled']]: isDisabled,
        },
      )}
      id={id}
      data-testid={id}
    >
      <div className={styles.content}>
        <div className={styles['image-container']}>
          {!!imageSrc ? (
            <img
              className={styles.image}
              alt={getPropertyAddress(property) || ''}
              src={imageSrc}
            />
          ) : (
            <StaticMapImage
              size={PropertyMapSize.small}
              height={56}
              width={74}
              zoomLevel={ZoomLevel.small}
              wrapperClassName={styles.image}
              property={property}
            />
          )}
        </div>

        <div>
          {isAddressClickable ? (
            <AYLink
              wrapperClassName={classNames(
                styles.line1,
                styles['clickable-address'],
              )}
              to={locations.showProperty(property?.id!)}
            >
              {getPropertyAddress(property)}
              <div className={styles['title-icon']}>
                <AlternateAddressIconSmall property={property} />
              </div>
            </AYLink>
          ) : (
            <h2 className={styles.line1}>{getPropertyAddress(property)}</h2>
          )}
          <h2 className={styles.line2}>{property.name || DASH_PLACEHOLDER}</h2>
          <h3 className={styles.line3}>
            {propertyMarketInfo.filter(Boolean).join(` ${DOT} `)}
          </h3>
        </div>
      </div>
      {hasTopLeftButton && (
        <div className={styles['top-button-container']}>
          <button
            type="button"
            onClick={onTopLeftButtonClick}
            className={classNames(styles['top-left-button'], {
              [styles.featured]: !isPropertyFeatured,
              [styles['featured-active']]: isPropertyFeatured,
            })}
          />
        </div>
      )}
      {hasTopRightButton && (
        <div className={styles['top-right-button-container']}>
          <button
            type="button"
            onClick={onTopRightButtonClick}
            disabled={isDisabled}
            className={classNames(
              topRightButtonClassName,
              styles['top-right-button'],
            )}
            aria-label={`${getPropertyAddress(property)} ${t(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.action`,
            )}`}
          />
        </div>
      )}
    </div>
  );
};

export default PropertyCardListItem;
