import React from 'react';

import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { REGEX_INTEGERS, REGEX_THREE_DECIMALS } from 'constants/regex';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { TIMES } from 'constants/placeholders';
import { YES_NO_OPTIONS } from 'constants/commonOptions';
import {
  RAIL_PROVIDER_OPTIONS,
  RAIL_SERVICE_OPTIONS,
} from 'constants/railOptions';
import { DOCK_CONFIGURATION_OPTIONS } from 'constants/dockConfigutations';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH,
} from 'constants/i18n';
import { FieldType } from 'components/CreateComps/FormSection/PropertyForm/types';
import LoadingDocks from 'components/CreateComps/FormSection/FormFields/LoadingDocks';
import DriveInDoors from 'components/CreateComps/FormSection/FormFields/DriveInDoors';
import ClearHeightMin from 'components/CreateComps/FormSection/FormFields/ClearHeightMin';
import ClearHeightMax from 'components/CreateComps/FormSection/FormFields/ClearHeightMax';
import {
  getFieldNameForProperty,
  getFieldValueForProperty,
  getIndustrialFieldName,
  getIndustrialFieldValue,
  getUnitOfMeasurementForProperty,
  PropertyEntitiesType,
} from 'utils/unitsOfMeasurement';
import { translateText } from 'utils/i18n';

import styles from '../../FormSection.module.scss';
import { SPRINKLERS_OPTIONS } from './constants';
import { CraneSpecificationFields } from '../../FormFields/CraneSpecificationFields';
import { updateBooleanOptions } from '../../formUtils';

interface Props {
  property: IPropertyInput;
  onChange: {
    [FieldType.common]: (fieldName: string, fieldValue: any) => void;
    [FieldType.industrial]: (fieldName: string, fieldValue: any) => void;
  };
  classNames: {
    formControlClassName: string;
    choiceInputClassName: string;
    inputHelperClassName: string;
  };
}

const IndustrialFields: React.FC<Props> = ({
  onChange,
  property,
  property: { industrial },
  classNames: {
    formControlClassName,
    choiceInputClassName,
    inputHelperClassName,
  },
}) => {
  const updateSiteSize = (value: string) => {
    onChange[FieldType.common](
      getFieldNameForProperty('siteSize', property),
      value || null,
    );
  };

  const updateColumnSpacingSize = (field: string, value: string) => {
    onChange[FieldType.industrial](
      getIndustrialFieldName(field, property),
      value || null,
    );
  };

  const getMeasurement = (fieldName: string, model: PropertyEntitiesType) =>
    getUnitOfMeasurementForProperty(
      fieldName,
      model,
      property?.propertyCountry?.code || property?.measurementSystem,
    );

  const hasRailLine =
    property?.industrial?.railServed?.id &&
    property?.industrial?.railServed?.id !== 4;

  return (
    <>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.siteSize`,
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          placeholder={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterSize`,
          )}
          pattern={REGEX_THREE_DECIMALS}
          value={getFieldValueForProperty(
            'siteSize',
            property,
            ModelsWithUnitsOfMeasurement.Property,
          )}
          onChange={value => updateSiteSize(value)}
        />
        <span className={inputHelperClassName}>
          {getMeasurement('siteSize', ModelsWithUnitsOfMeasurement.Property)}
        </span>
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.zoning`,
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          placeholder={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.zoning`,
          )}
          value={property?.zoning}
          onChange={value => {
            onChange[FieldType.common]('zoning', value);
          }}
        />
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.businessParkType`,
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          fullWidth
          borderBox
          placeholder={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterParkType`,
          )}
          value={industrial?.businessParkType}
          onChange={value =>
            onChange[FieldType.industrial]('businessParkType', value)
          }
        />
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.transportationConnectivity`,
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          fullWidth
          borderBox
          value={industrial?.transportationConnectivity}
          placeholder={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterTransportationConnectivity`,
          )}
          onChange={value =>
            onChange[FieldType.industrial]('transportationConnectivity', value)
          }
        />
      </FormControl>
      <LoadingDocks
        onChange={onChange[FieldType.industrial]}
        value={industrial?.loadingDocks}
      />
      <DriveInDoors
        onChange={onChange[FieldType.industrial]}
        value={industrial?.driveInDoors}
      />

      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.dockConfiguration`,
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          selectedItem={{
            id: industrial?.dockConfigurationType?.id,
          }}
          itemWrapperClassName={styles['form-margin-bottom-control']}
          data={DOCK_CONFIGURATION_OPTIONS}
          onChange={item => {
            onChange[FieldType.industrial]('dockConfigurationType', item);
          }}
        />
      </FormControl>

      <ClearHeightMin
        onChange={onChange[FieldType.industrial]}
        property={property}
        value={getIndustrialFieldValue('typicalClearHeightMin', property!)}
      />
      <ClearHeightMax
        onChange={onChange[FieldType.industrial]}
        property={property}
        value={getIndustrialFieldValue('typicalClearHeightMax', property!)}
      />
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.columnSpacing`,
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          id="columnSpacingMinInput"
          isNarrow
          textCentered
          placeholder={'00'}
          pattern={REGEX_INTEGERS}
          value={getIndustrialFieldValue('columnSpacingMin', property!)}
          onChange={value => updateColumnSpacingSize('columnSpacingMin', value)}
        />
        <span className={inputHelperClassName}>{TIMES}</span>
        <TextInput
          id="columnSpacingMaxInput"
          isNarrow
          textCentered
          placeholder={'00'}
          pattern={REGEX_INTEGERS}
          value={getIndustrialFieldValue('columnSpacingMax', property!)}
          onChange={value => updateColumnSpacingSize('columnSpacingMax', value)}
        />
        <span className={inputHelperClassName}>
          {getMeasurement(
            'columnSpacingMax',
            ModelsWithUnitsOfMeasurement.Office,
          )}
        </span>
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.trailerParkingSpaces`,
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          pattern={REGEX_INTEGERS}
          placeholder={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterSpaces`,
          )}
          value={industrial?.trailerParkingSpaces}
          onChange={value =>
            onChange[FieldType.industrial]('trailerParkingSpaces', value)
          }
        />
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.parkingSpots`,
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          pattern={REGEX_INTEGERS}
          placeholder={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterSpaces`,
          )}
          value={property?.parkingSpots}
          onChange={value => onChange[FieldType.common]('parkingSpots', value)}
        />
      </FormControl>

      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.railService`,
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          data={RAIL_SERVICE_OPTIONS}
          containerWrapperClassName={choiceInputClassName}
          selectedItem={{
            id: property?.industrial?.railServed?.id,
          }}
          onChange={value =>
            onChange[FieldType.industrial]('railServed', value)
          }
        />
      </FormControl>

      {hasRailLine && (
        <FormControl
          label={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.railLine`,
          )}
          wrapperClassName={formControlClassName}
        >
          <ChoiceInput
            data={RAIL_PROVIDER_OPTIONS}
            containerWrapperClassName={choiceInputClassName}
            selectedItem={{
              id: property?.industrial?.railProvider?.id,
            }}
            onChange={value =>
              onChange[FieldType.industrial]('railProvider', value)
            }
          />
        </FormControl>
      )}

      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.outsideStorage`,
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          data={YES_NO_OPTIONS}
          containerWrapperClassName={choiceInputClassName}
          selectedItem={{
            name: industrial?.outsideStorage,
          }}
          labelFieldName="id"
          onChange={value =>
            onChange[FieldType.industrial](
              'outsideStorage',
              updateBooleanOptions(value),
            )
          }
        />
      </FormControl>
      <CraneSpecificationFields
        property={property}
        formControlClassName={formControlClassName}
        choiceInputClassName={choiceInputClassName}
        onChange={onChange}
        getMeasurement={getMeasurement}
      />
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.sprinklers`,
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          data={SPRINKLERS_OPTIONS}
          containerWrapperClassName={choiceInputClassName}
          onChange={value =>
            onChange[FieldType.industrial]('sprinklers', value?.name)
          }
          selectedItem={{
            id: industrial?.sprinklers,
            name: industrial?.sprinklers,
          }}
        />
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.buildingDepth`,
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          pattern={REGEX_INTEGERS}
          placeholder={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterDepth`,
          )}
          value={industrial?.buildingDepth}
          onChange={value =>
            onChange[FieldType.industrial]('buildingDepth', value)
          }
        />
      </FormControl>
    </>
  );
};

export default IndustrialFields;
