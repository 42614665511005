import React from 'react';

import FormControl from 'components/FormControl';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend';
import { ErrorLabel } from 'components/ErrorLabel';
import TextInput from 'components/Inputs/TextInput';
import { YES_NO_OPTIONS } from 'constants/commonOptions';
import { REGEX_DECIMALS, REGEX_INTEGERS } from 'constants/regex';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import {
  getFieldNameForProperty,
  getFieldValueForProperty,
  PropertyEntitiesType,
} from 'utils/unitsOfMeasurement';
import { translateText } from 'utils/i18n';

import { hasPowerCapacityError } from '../../PropertyForm/utils';
import { FieldType } from '../../PropertyForm/types';
import { updateBooleanOptions } from '../../formUtils';

interface Props {
  property: IPropertyInput;
  onChange: {
    [FieldType.common]: (fieldName: string, fieldValue: string) => void;
    [FieldType.industrial]: (
      fieldName: string,
      fieldValue: boolean | null | string,
    ) => void;
  };
  getMeasurement: (fieldName: string, model: PropertyEntitiesType) => string;
  formControlClassName?: string;
  choiceInputClassName?: string;
}
export const CraneSpecificationFields = ({
  property,
  onChange,
  getMeasurement,
  formControlClassName,
  choiceInputClassName,
}: Props) => {
  return (
    <>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.craneServed',
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          data={YES_NO_OPTIONS}
          containerWrapperClassName={choiceInputClassName}
          selectedItem={{
            name: property?.industrial?.craneServed,
          }}
          labelFieldName="id"
          onChange={value =>
            onChange[FieldType.industrial](
              'craneServed',
              updateBooleanOptions(value),
            )
          }
        />
      </FormControl>

      {property?.industrial?.craneServed && (
        <FormControl
          label={translateText(
            'avantPlatform.attributes.property.label.craneCapacity',
          )}
          wrapperClassName={formControlClassName}
        >
          <TextInput
            id="craneCapacity"
            pattern={REGEX_INTEGERS}
            placeholder={translateText(
              'avantPlatform.attributes.property.prompt.enterCapacity',
            )}
            value={property?.industrial?.craneCapacity}
            onChange={value =>
              onChange[FieldType.industrial]('craneCapacity', value)
            }
          />
          <UnitOfMeasurementLegend
            shouldConvert={false}
            unitOfMeasurement={translateText(
              'avantPlatform.common.abbreviations.tons',
            )}
          />
        </FormControl>
      )}
      {property?.industrial?.craneServed && (
        <FormControl
          label={translateText(
            'avantPlatform.attributes.property.label.hookHeight',
          )}
          wrapperClassName={formControlClassName}
        >
          <TextInput
            isNarrow
            id="hookHeight"
            pattern={REGEX_DECIMALS}
            value={getFieldValueForProperty(
              'hookHeight',
              property,
              ModelsWithUnitsOfMeasurement.Industrial,
            )}
            placeholder="00"
            onChange={value => {
              onChange[FieldType.industrial](
                getFieldNameForProperty(
                  'hookHeight',
                  property,
                  ModelsWithUnitsOfMeasurement.Industrial,
                ),
                value,
              );
            }}
          />
          <UnitOfMeasurementLegend
            shouldConvert={false}
            unitOfMeasurement={getMeasurement(
              'hookHeight',
              ModelsWithUnitsOfMeasurement.Industrial,
            )}
          />
        </FormControl>
      )}
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.heavyPower',
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          data={YES_NO_OPTIONS}
          containerWrapperClassName={choiceInputClassName}
          selectedItem={{
            name: property?.industrial?.heavyPower,
          }}
          labelFieldName="id"
          onChange={value =>
            onChange[FieldType.industrial](
              'heavyPower',
              updateBooleanOptions(value),
            )
          }
        />
      </FormControl>
      {property?.industrial?.heavyPower && (
        <>
          <FormControl
            label={translateText(
              'avantPlatform.attributes.property.label.powerCapacity',
            )}
            wrapperClassName={formControlClassName}
          >
            <TextInput
              id="amps"
              pattern={REGEX_DECIMALS}
              placeholder={translateText(
                'avantPlatform.attributes.property.prompt.enterPower',
              )}
              value={property?.industrial?.amps}
              isInvalid={hasPowerCapacityError(property)}
              onChange={value => onChange[FieldType.industrial]('amps', value)}
            />
            <UnitOfMeasurementLegend
              shouldConvert={false}
              unitOfMeasurement={translateText(
                'avantPlatform.common.abbreviations.amps',
              )}
            />

            {hasPowerCapacityError(property) && (
              <div>
                <ErrorLabel
                  errorMessage={translateText(
                    'avantProperties.pages.findComps.powerCapacityError',
                  )}
                />
              </div>
            )}
          </FormControl>
        </>
      )}
    </>
  );
};
